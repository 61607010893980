body {
  font-family: 'Poppins', Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  text-align: center;
  padding: 0; /* Ensure no padding */
  margin: 0; /* Ensure no margin */
}

.App-header {
  background-color: var(--background-color);
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--text-color);
  padding: 0;
  margin: 0;
}

.input-form {
  margin: 20px;
}

.input-form label {
  margin: 0 10px;
}

.Map-container {
  display: flex;
  flex-direction: column;
  height: 60vh; /* Use viewport height for responsiveness */
  width: 100%;
}

.Map-wrapper {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Map-wrapper > div {
  height: 100%; /* Ensure the map takes up the full height of the container */
  width: 100%; /* Ensure the map takes up the full width of the container */
}

.paper {
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--paper-background-color);
  color: var(--paper-text-color);
  box-sizing: border-box;
}

.scrollable-card {
  max-height: 60vh; /* Adjust height as needed */
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.inner-card {
  padding: 10px;
  background-color: var(--inner-card-background-color);
  color: var(--inner-card-text-color);
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .Map-container {
    height: 50vh; /* Adjust height for smaller screens */
  }

  .scrollable-card {
    max-height: 50vh; /* Adjust height for smaller screens */
  }
}

  